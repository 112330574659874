import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Text from '@tiptap/extension-text';
import Color from '@tiptap/extension-color';
import Typography from '@tiptap/extension-typography';
import FontFamily from '@tiptap/extension-font-family';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import Gapcursor from '@tiptap/extension-gapcursor';
import { FontSize } from './extension/FontSize';
import { LineHeight } from './extension/LineHeight';
import { CustomImage } from './extension/CustomImage';
import { all, createLowlight } from 'lowlight';
import MenuBar from './MenuBar';
import 'highlight.js/styles/github-dark.css';
import './index.less';
import clsx from 'clsx';

const lowlight = createLowlight(all);

export interface RichTextEditorProps {
  value?: string;
  onChange?: (html: string) => void;
  placeholder?: string;
  className?: string;
  contentStyle?: React.CSSProperties;
  errorStatus?: boolean;
  disabled?: boolean;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value = '',
  onChange,
  placeholder = 'Start writing...',
  className,
  contentStyle,
  errorStatus,
  disabled,
}) => {
  const [content, setContent] = useState(value);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        codeBlock: false,
        bulletList: false, // 禁用默认的无序列表
        orderedList: false, // 禁用默认的有序列表
      }),
      CodeBlockLowlight.configure({ lowlight }),
      Image,
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: 'text-blue-500 underline',
        },
      }),
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder,
        showOnlyWhenEditable: true,
      }),
      TextStyle,
      Text,
      Color,
      Typography,
      FontFamily.configure({
        types: ['textStyle'],
      }),
      BulletList,
      OrderedList,
      ListItem,
      Superscript,
      Subscript,
      Gapcursor,
      FontSize,
      LineHeight,
      CustomImage,
    ],
    content,
    onUpdate: ({ editor }) => {
      setContent?.(editor.getHTML());
    },
  });

  const handleImageUpload = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  useEffect(() => {
    if (!editor) return;
    editor.commands.setContent(value);
  }, [value, editor]);

  useEffect(() => {
    if (!editor) return;
    editor?.setEditable(!disabled);
  }, [editor, disabled]);

  const onImageSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!editor) return;

      const file = event.target.files?.[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result === 'string') {
          // 插入图片并将光标设置到图片之后
          editor
            .chain()
            .focus()
            .setImage({ src: result })
            .insertContent(' ') // 插入一个空格字符，确保光标在图片后
            .setTextSelection(editor.state.selection.$anchor.pos + 1) // 设置光标到插入内容之后
            .run();
        }
      };
      reader.readAsDataURL(file);

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [editor],
  );

  const handleBlur = useCallback(() => {
    onChange?.(content); // 调用外部回调
  }, [content, onChange]);

  return (
    <div
      className={clsx(
        'w-full border border-solid border-gray-200 rounded-lg overflow-hidden bg-white',
        errorStatus ? 'border-red-500' : '',
        className,
      )}
      onBlur={handleBlur}>
      <MenuBar editor={editor} onImageUpload={handleImageUpload} />
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onChange={onImageSelect}
      />
      <EditorContent
        editor={editor}
        className="prose max-w-none p-4 min-h-[200px] focus:outline-none"
        style={contentStyle}
      />
    </div>
  );
};

export default RichTextEditor;
