import Editor from 'src/components/Editor';
import type { RichTextEditorProps } from 'src/components/Editor';
import { mapProps, connect } from '@formily/react';
import { Field } from '@formily/core';

export const EditorWrapper = connect(
  Editor,
  mapProps((props: RichTextEditorProps, field) => {
    return {
      ...props,
      value: props.value || (field as Field).value,
      onChange: (field as Field).onInput,
      errorStatus: !!(field as Field).errors?.[0],
      disabled: props.disabled ?? (field as Field).disabled,
    };
  }),
);
