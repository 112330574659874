import { TreeSelect } from 'antd';
import type { TreeSelectProps } from 'antd';
import { useRequest } from 'ahooks';
import { getDeptList } from 'src/api/clients/system';
import { QuerySceneEnum } from 'src/api/types/common';
import { IDeptItem } from 'src/api/types/system';

export interface IDeptSelectProps extends TreeSelectProps {
  placeholder?: string;
}

export const DeptSelect = (props: IDeptSelectProps) => {
  const { ...extra } = props;
  const { data: deptTree } = useRequest(async () => {
    const result = await getDeptList({
      offset: 0,
      limit: 10000,
      scene: QuerySceneEnum.TREE,
    });
    const keys = [] as number[];
    const transformData = (data: IDeptItem): TreeSelectProps => {
      const result: TreeSelectProps = {
        title: (data.deptName ?? '') as string,
        value: Number(data?.deptId),
      };
      if (data.children) {
        keys.push(Number(data.deptId));
        result.children = data.children?.map(transformData).filter(Boolean) as any[];
      }
      return result;
    };
    const tree = result?.map(transformData) as TreeSelectProps[];
    return tree;
  });

  return <TreeSelect treeData={deptTree as any[]} treeDefaultExpandAll {...extra} />;
};
