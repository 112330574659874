import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import React, { useState } from 'react';

const ResizableImage: React.FC<NodeViewProps> = ({ node, updateAttributes }) => {
  const [width, setWidth] = useState(node.attrs.width || 300);
  const handleMouseDown = (event: React.MouseEvent, position: 'left' | 'right') => {
    const startX = event.clientX;

    const onMouseMove = (moveEvent: MouseEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const adjustment = position === 'right' ? deltaX : -deltaX;

      const newWidth = Math.max(50, width + adjustment);
      setWidth(newWidth);
    };

    const onMouseUp = () => {
      updateAttributes({ width });
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  return (
    <NodeViewWrapper className="relative">
      <div style={{ width: `${width}px` }} className="relative group overflow-hidden">
        <img src={node.attrs.src} alt="" className="w-full h-auto" />
        <div
          className="absolute top-0 bottom-0 left-0 w-1 hover:bg-blue-500 cursor-col-resize"
          onMouseDown={(e) => handleMouseDown(e, 'left')}
        />
        <div
          className="absolute top-0 bottom-0 right-0 w-1 hover:bg-blue-500 cursor-col-resize"
          onMouseDown={(e) => handleMouseDown(e, 'right')}
        />
      </div>
    </NodeViewWrapper>
  );
};

export default ResizableImage;
