import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import ResizableImage from '../components/ResizableImage';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customImage: {
      /**
       * Set the font size attribute
       */
      setImage: (options: { src: string; width?: number; height?: number }) => ReturnType;
    };
  }
}

export const CustomImage = Node.create({
  name: 'customImage',
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ResizableImage);
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ chain, editor }) => {
          return chain()
            .insertContent([
              {
                type: this.name,
                attrs: options,
              },
              { type: 'paragraph' },
            ])
            .command(({ tr }) => {
              const pos = tr.selection.$anchor.pos; // 获取当前光标位置
              const newPos = pos + 1; // 移动光标到段落
              editor.commands.setTextSelection(newPos);
              return true;
            })
            .run();
        },
    };
  },
});
