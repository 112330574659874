import React from 'react';
import { Editor } from '@tiptap/react';
import { ColorPicker } from 'antd';
import type { Color } from 'antd/es/color-picker';

interface ColorSelectorProps {
  editor: Editor;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({ editor }) => {
  const handleColorChange = (color: Color) => {
    const colors =
      color.toHexString()?.length > 7 ? color.toHexString().slice(0, 7) : color.toHexString();
    editor.chain().focus().setColor(colors).run();
  };
  return (
    <ColorPicker
      presets={[
        {
          label: '常见颜色',
          colors: [
            '#000000',
            '#EF4444',
            '#F97316',
            '#F59E0B',
            '#10B981',
            '#06B6D4',
            '#3B82F6',
            '#6366F1',
            '#8B5CF6',
            '#EC4899',
            '#FFFFFF',
          ],
        },
      ]}
      defaultValue="#000000"
      onChange={handleColorChange}
      panelRender={(panel) => <div className="p-1">{panel}</div>}
    />
  );
};

export default ColorSelector;
