import React from 'react';
import { Editor } from '@tiptap/react';
import { cn } from './utils/cn';
import {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Code,
  List,
  ListOrdered,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Link,
  Image,
  Undo,
  Redo,
  Terminal,
  Quote,
  Subscript,
  Superscript,
} from 'lucide-react';
import ColorSelector from './ColorSelector';
import FontControls from './FontControls';

interface MenuBarProps {
  editor: Editor | null;
  onImageUpload: () => void;
}

const MenuBar: React.FC<MenuBarProps> = ({ editor, onImageUpload }) => {
  if (!editor) return null;

  const addLink = () => {
    const url = window.prompt('Enter URL:');
    if (url) {
      editor.chain().focus().setLink({ href: url }).run();
    }
  };

  const addCodeBlock = () => {
    editor.chain().focus().toggleCodeBlock().run();
  };

  return (
    <div
      className="sticky top-0 z-10 space-y-2 p-2 border-solid bg-white border-b border-t-0 border-x-0 border-gray-200"
      onClick={(e) => e.preventDefault() /* 防止触发失焦 */}>
      <div className="flex items-center justify-start gaflex pb-2 border-b border-gray-100 gap-1">
        <FontControls editor={editor} />
        <ColorSelector editor={editor} />
      </div>

      <div className="flex flex-wrap gap-1">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg shrink-0 w-8 h-8',
            editor.isActive('bold') && 'bg-gray-100',
          )}>
          <Bold className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg shrink-0 w-8 h-8',
            editor.isActive('italic') && 'bg-gray-100',
          )}>
          <Italic className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive('underline') && 'bg-gray-100',
          )}>
          <Underline className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive('strike') && 'bg-gray-100',
          )}>
          <Strikethrough className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive('code') && 'bg-gray-100',
          )}>
          <Code className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleSuperscript().run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive('superscript') && 'bg-gray-100',
          )}>
          <Superscript className="w-5 h-5" />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleSubscript().run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive('subscript') && 'bg-gray-100',
          )}>
          <Subscript className="w-5 h-5" />
        </button>
        <div className="self-center w-px h-6 mx-1 bg-gray-200" />

        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive('bulletList') && 'bg-gray-100',
          )}>
          <List className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive('orderedList') && 'bg-gray-100',
          )}>
          <ListOrdered className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive('blockquote') && 'bg-gray-100',
          )}>
          <Quote className="w-5 h-5" />
        </button>

        <div className="self-center w-px h-6 mx-1 bg-gray-200" />

        <button
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive({ textAlign: 'left' }) && 'bg-gray-100',
          )}>
          <AlignLeft className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive({ textAlign: 'center' }) && 'bg-gray-100',
          )}>
          <AlignCenter className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive({ textAlign: 'right' }) && 'bg-gray-100',
          )}>
          <AlignRight className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign('justify').run()}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive({ textAlign: 'justify' }) && 'bg-gray-100',
          )}>
          <AlignJustify className="w-5 h-5" />
        </button>

        <div className="self-center w-px h-6 mx-1 bg-gray-200" />

        <button
          onClick={addCodeBlock}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive('codeBlock') && 'bg-gray-100',
          )}>
          <Terminal className="w-5 h-5" />
        </button>
        <button
          onClick={addLink}
          className={cn(
            'flex justify-center items-center hover:bg-gray-100 rounded-lg w-8 h-8',
            editor.isActive('link') && 'bg-gray-100',
          )}>
          <Link className="w-5 h-5" />
        </button>
        <button
          onClick={onImageUpload}
          className="flex justify-center items-center rounded-lg hover:bg-gray-100">
          <Image className="w-5 h-5" />
        </button>

        <div className="self-center w-px h-6 mx-1 bg-gray-200" />

        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().undo()}
          className="flex justify-center items-center rounded-lg hover:bg-gray-100 disabled:opacity-50 w-8 h-8 disabled:cursor-not-allowed">
          <Undo className="w-5 h-5" />
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().redo()}
          className="flex justify-center items-center rounded-lg hover:bg-gray-100 disabled:opacity-50 w-8 h-8 disabled:cursor-not-allowed">
          <Redo className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default MenuBar;
