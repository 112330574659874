import { useMemo, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { Input, Radio, Select } from '@formily/antd-v5';
import { Button, Modal, message } from 'antd';
import { AiOutlinePlus, AiOutlineDelete } from 'react-icons/ai';

import { deleteNotice, getNoticeList, pushNotice } from 'src/api/clients/system';
import { IGetNoticeListReq, INoticeItem } from 'src/api/types/system';

import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { schema, columns } from './schema';
import { NoticeDrawer } from './notice-drawer';

const SystemNoticeTableName = 'SystemNoticeTableName';

export default function SystemNotice() {
  const [open, { setTrue, setFalse }] = useBoolean(false);
  const [current, setCurrent] = useState<INoticeItem>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const getRolesTableList = useMemoizedFn(async (params: IGetNoticeListReq) => {
    const result = await getNoticeList(params);
    return {
      data: result.rows,
      total: result.count,
    };
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onPushNotice = useMemoizedFn(async (notice: INoticeItem) => {
    Modal.confirm({
      title: '注意',
      content: `确定推送指定公告吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await pushNotice({ id: notice.noticeId, content: notice.noticeContent });
        message.success('推送成功');
        onRefresh();
      },
    });
  });

  const onDeleteNoticeByIds = useMemoizedFn(async (ids: number[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定公告吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteNotice(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      SystemNoticeTableName,
    );
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 150,
        renderType: 'Operation',
        renderProps: (col: INoticeItem) => {
          return {
            buttons: [
              {
                label: '推送',
                onClick: () => {
                  onPushNotice(col);
                },
              },
              {
                label: '编辑',
                onClick: () => {
                  setCurrent(col);
                  setTrue();
                },
              },
              {
                label: '删除',
                onClick: async () => {
                  await onDeleteNoticeByIds([col.noticeId]);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <div className="m-4 p-6 bg-white rounded-md">
      <div className="text-base font-semibold mb-4">通知公告</div>
      <QueryTable.Provider name={SystemNoticeTableName} requestFn={getRolesTableList}>
        <QueryTable.Filter schema={schema} components={{ Input, Select, Radio }} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="noticeId"
          scroll={{ x: 1200 }}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          tabsNode={
            <Button icon={<AiOutlinePlus />} type="primary" onClick={setTrue}>
              新增公告
            </Button>
          }
          extraRightTool={
            <Button
              disabled={!selectedRowKeys.length}
              color="danger"
              icon={<AiOutlineDelete />}
              onClick={() => {
                onDeleteNoticeByIds(selectedRowKeys as number[]);
              }}
              variant="outlined">
              批量删除
            </Button>
          }
        />
      </QueryTable.Provider>
      <NoticeDrawer
        open={open}
        onCancel={() => {
          setFalse();
          setCurrent(undefined);
        }}
        onOk={onRefresh}
        data={current}
      />
    </div>
  );
}
