import { useRef, useMemo, useEffect, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { message, Drawer, Button, Space } from 'antd';
import type { DrawerProps } from 'antd';
import { Input, Select, Radio, NumberPicker, Switch } from '@formily/antd-v5';
import { Form, FormRef } from 'src/components/Form';
import { InputIcon } from 'src/components/formily';
import { INoticeItem } from 'src/api/types/system';
import { updateNotice, createNotice } from 'src/api/clients/system';
import { onFormMount } from '@formily/core';
import { operationSchema } from './schema';
import { EditorWrapper } from 'src/components/formily/EditorWrapper';
import { DeptSelectForm } from 'src/components/formily/DeptSelectForm';
import { StatusEnum } from 'src/api/types/common';

interface IProps extends DrawerProps {
  data?: INoticeItem;
  onOk?: () => void;
  onCancel?: () => void;
}

export function NoticeDrawer(props: IProps) {
  const { open, onOk, onCancel, data, ...extra } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);
  const formRef = useRef<FormRef | null>(null);
  const [formMounted, setFormMounted] = useState(false);

  const isEdit = !!data?.noticeId;

  const onCancelHandle = useMemoizedFn(() => {
    const formInstance = formRef.current?.getFormInstance();
    formInstance?.reset();
    onCancel?.();
  });

  const onSubmit = useMemoizedFn(async () => {
    const formInstance = formRef.current?.getFormInstance();
    const values = await formInstance?.submit<INoticeItem>();
    const { ...rest } = values ?? {};
    const bodyData = {
      ...rest,
    };
    if (isEdit) {
      bodyData.noticeId = data?.noticeId;
    }
    setTrue();
    const request = isEdit ? updateNotice : createNotice;
    try {
      const res = await request(bodyData as INoticeItem);
      if (res) {
        message.success(`${isEdit ? '更新' : '添加'}成功`);
        onCancelHandle();
        onOk?.();
      }
    } finally {
      setFalse();
    }
  });

  const formOptions = useMemo(() => {
    return {
      effects() {
        onFormMount(() => {
          setFormMounted(true);
        });
      },
    };
  }, []);

  useEffect(() => {
    if (formMounted) {
      const formInstance = formRef.current?.getFormInstance();
      const { noticeTitle, noticeType, noticeContent, status, target } = data || {};
      formInstance?.setValues({
        noticeTitle,
        noticeType,
        noticeContent,
        status: status ?? StatusEnum.NORMAL,
        target,
      });
      formInstance?.setFieldState('noticeType', (field) => {
        field.disabled = !!data;
      });
      formInstance?.setFieldState('target', (field) => {
        field.disabled = !!data;
      });
      formInstance?.setFieldState('noticeContent', (field) => {
        field.disabled = !!data;
      });
    }
  }, [formMounted, data]);

  return (
    <Drawer
      open={open}
      title={isEdit ? '编辑公告' : '新增公告'}
      extra={
        <Space>
          <Button onClick={onCancelHandle}>取消</Button>
          <Button loading={loading} onClick={onSubmit} type="primary">
            提交
          </Button>
        </Space>
      }
      width={500}
      onClose={onCancelHandle}
      {...extra}>
      <Form
        ref={formRef}
        components={{
          Input,
          Select,
          Radio,
          NumberPicker,
          Switch,
          InputIcon,
          EditorWrapper,
          DeptSelectForm,
        }}
        schema={operationSchema}
        formOptions={formOptions}
        hideAction
        layout={{ layout: 'vertical' }}
      />
    </Drawer>
  );
}
