import React from 'react';
import { Editor } from '@tiptap/react';
import { Select } from 'antd';
import { cn } from './utils/cn';

interface FontControlsProps {
  editor: Editor;
}

const fontSizes = ['12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px', '48px'];

const fontFamilies = [
  { label: 'Default', value: 'Inter' },
  { label: 'Serif', value: 'Georgia' },
  { label: 'Mono', value: 'Monaco' },
  { label: 'System', value: 'system-ui' },
  { label: '黑体', value: 'SimHei' },
  { label: '楷体', value: 'KaiTi' },
  { label: '宋体', value: 'SimSun' },
  { label: '仿宋', value: 'FangSong' },
  { label: '微软雅黑', value: 'Microsoft YaHei' },
  { label: 'Arial', value: 'Arial' },
  { label: 'Times New Roman', value: 'Times New Roman' },
  { label: 'Courier New', value: 'Courier New' },
  { label: 'Verdana', value: 'Verdana' },
];

const lineHeights = [
  { label: '默认行高', value: 'normal' },
  { label: '1', value: '1' },
  { label: '1.15', value: '1.15' },
  { label: '1.5', value: '1.5' },
  { label: '2', value: '2' },
  { label: '2.5', value: '2.5' },
  { label: '3', value: '3' },
];

const FontControls: React.FC<FontControlsProps> = ({ editor }) => {
  const handleFontSizeChange = (value: string) => {
    editor.chain().focus().setFontSize(value).run();
  };

  const handleFontFamilyChange = (value: string) => {
    editor.chain().focus().setFontFamily(value).run();
  };

  const handleLineHeightChange = (value: string) => {
    editor.chain().focus().setLineHeight(value).run();
  };

  return (
    <div className="flex gap-2">
      <Select
        className={cn('w-20')}
        defaultValue="14px"
        onChange={handleFontSizeChange}
        options={fontSizes.map((size) => ({ label: size, value: size }))}
      />
      <Select
        className={cn('w-48')}
        defaultValue="Inter"
        onChange={handleFontFamilyChange}
        options={fontFamilies}
        popupMatchSelectWidth={140}
      />
      <Select
        className={cn('w-28')}
        defaultValue="normal"
        onChange={handleLineHeightChange}
        options={lineHeights}
        popupMatchSelectWidth={120}
      />
    </div>
  );
};

export default FontControls;
