import { Tag, TreeSelect } from 'antd';
import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';
import { StatusOptions } from 'src/constants/system';
import type { IFormSchema } from 'src/components/Form';
import { NoticeTypeEnum, StatusEnum } from 'src/api/types/common';
import dayjs from 'dayjs';

export const columns: QueryTableColumnProps = [
  {
    title: '公告ID',
    dataIndex: 'noticeId',
    key: 'noticeId',
    width: 100,
  },
  {
    title: '公告名称',
    dataIndex: 'noticeTitle',
    key: 'noticeTitle',
    width: 200,
  },
  {
    title: '公告类型',
    dataIndex: 'noticeType',
    key: 'noticeType',
    width: 200,
    render: (noticeType: string) => {
      return noticeType === NoticeTypeEnum.NOTICE ? (
        <Tag color="processing">通知</Tag>
      ) : (
        <Tag color="gold">公告</Tag>
      );
    },
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status: string) => {
      return (
        <Tag color={status === StatusEnum.NORMAL ? 'green' : 'red'}>
          {status === StatusEnum.NORMAL ? '正常' : '禁用'}
        </Tag>
      );
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 200,
    render: (createTime: string) => {
      return dayjs(createTime).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];

export const schema: QueryTableFilterISchemaProperties = {
  noticeTitle: {
    type: 'string',
    title: '公告名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入公告名称',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
  noticeType: {
    type: 'string',
    title: '公告类型',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择公告类型',
      options: [
        { label: '通知', value: NoticeTypeEnum.NOTICE },
        { label: '公告', value: NoticeTypeEnum.ANNOUNCEMENT },
      ],
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
  status: {
    type: 'number',
    title: '状态',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择状态',
      options: [
        { value: StatusEnum.NORMAL, label: '正常' },
        { value: StatusEnum.STOP, label: '关闭' },
      ],
    },
  },
};

export const operationSchema: IFormSchema = {
  noticeTitle: {
    type: 'string',
    title: '标题名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入标题名称',
      showCount: true,
      maxLength: 50,
    },
    'x-validator': [
      { required: true, message: '请输入标题名称' },
      { max: 50, message: '标题名称不能超过50个字符' },
    ],
  },
  noticeType: {
    type: 'string',
    title: '公告类型',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入公告类型',
      options: [
        { label: '通知', value: NoticeTypeEnum.NOTICE },
        { label: '公告', value: NoticeTypeEnum.ANNOUNCEMENT },
      ],
    },
    'x-validator': [{ required: true, message: '请输入字典类型' }],
  },
  target: {
    type: 'string',
    title: '圈选人群',
    'x-component': 'DeptSelectForm',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择圈选人群',
      multiple: true,
      treeCheckable: true,
      showCheckedStrategy: TreeSelect.SHOW_ALL,
    },
    'x-decorator-props': {
      tooltip: '如不选择，则全部人员',
    },
  },
  noticeContent: {
    type: 'string',
    title: '公告内容',
    'x-component': 'EditorWrapper',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入公告内容',
      showCount: true,
      maxLength: 500,
      style: {
        height: 100,
      },
    },
    'x-decorator-props': {
      asterisk: true,
    },
    'x-validator': {
      validator(value: string) {
        if (!value || value.length < 8) {
          return '请输入公告内容';
        }
        return true;
      },
    },
  },
  status: {
    type: 'number',
    title: '状态',
    'x-component': 'Radio.Group',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择状态',
      options: StatusOptions,
    },
    default: StatusEnum.NORMAL,
    'x-validator': [{ required: true, message: '请选择状态' }],
  },
  remark: {
    type: 'string',
    title: '备注',
    'x-component': 'Input.TextArea',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入备注',
      showCount: true,
      maxLength: 500,
      style: {
        height: 100,
      },
    },
  },
};
